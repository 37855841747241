import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.css';
import { Box, Divider, Typography } from '@mui/material';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RAW_THEME } from 'theme';
import { getShebaBankName, toPersianDigit } from 'utils/digit';

const theme = createTheme({
  ...RAW_THEME,
  typography: {
    ...RAW_THEME.typography,
    body1: {
      fontWeight: 200,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.3rem',
      fontWeight: 400,
    },
  },
});

function Contract() {
  const {
    state: { record: user, channelUrl },
  } = useLocation();
  const navigate = useNavigate();
  if (!user.percent) {
    user.percent = prompt('درصد کارمزد را وارد نمایید.') || 17;
  }
  user.name =
    user.information?.firstName && user.information?.lastName
      ? `${user.information.firstName} ${user.information.lastName}`
      : null;
  useEffect(() => {
    const docTitle = document.title;
    function print() {
      document.title = `contact-${user.id}`;
      window.print();
      document.title = docTitle;
      // navigate(-1);
    }
    Promise.all([
      ...Array.from(document.images)
        .filter((img) => !img.complete)
        .map(
          (img) => new Promise((resolve) => {
            img.onload = img.onerror = resolve;
          }),
        ),
      document.fonts.ready,
    ]).then(() => {
      console.log('ready to print2');
      setTimeout(() => {
        print();
      }, 1000);
    });
  }, []);

  console.log(user);
  return <Madeha user={user} channelUrl={channelUrl} />;
}

export default Contract;

function insert(val, type) {
  if (!val) {
    return (
      <code style={{ backgroundColor: 'crimson', color: '#fff' }}>
        {' '}
        NOT SET
        {' '}
      </code>
    );
  }
  if (type === 'number') {
    return (
      <span style={{ fontWeight: 300 }}>
        {' '}
        {toPersianDigit(val)}
        {' '}
      </span>
    );
  }
  return (
    <span>
      {' '}
      {val}
      {' '}
    </span>
  );
}

function Madeha({ user, channelUrl }) {
  const signSection = (
    <Box sx={{ p: 2 }}>
      <Box sx={{ p: 2 }}>
        <table style={{ borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td
                style={{
                  width: '228pt',
                  paddingRight: '5.5pt',
                  paddingLeft: '5.5pt',
                  verticalAlign: 'top',
                }}
              >
                <Typography>
                  سید مصطفی میرقاسمی
                  <br />
                  نماینده طرف اول
                </Typography>
              </td>
              <td
                style={{
                  width: '228pt',
                  paddingRight: '5.5pt',
                  paddingLeft: '5.5pt',
                  verticalAlign: 'top',
                }}
              >
                <Typography>
                  {insert(user.name)}
                  <br />
                  طرف دوم
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
  return (
    <ThemeProvider theme={theme}>
      <div className="contract" style={{ textAlign: 'justify' }}>
        <Page footer={signSection}>
          <Divider>به نام خدا</Divider>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            <img
              src="https://youber.app/wp-content/uploads/2023/09/youber-logo-new.png"
              alt="youber"
              style={{ width: 140 }}
            />
          </Box>
          <Box sx={{ p: 2, pt: 6, pb: 3 }}>
            <Typography>
              این قرارداد در تاریخ
              {insert(new Date().toLocaleDateString('fa-IR'))}
              به منظور ارائه خدمات و تسهیلات جهت نقد کردن درآمد یوتیوبر و انتقال
              آن به ایران و واریز به حساب یوتیوبر، با عنایت به مواد ۱۰ ، ۱۹۰ و
              ۲۱۹ قانون مدنی و بر مبنای اصل آزادی اراده، احراز صحت و سلامت طرفین
              و رعایت قواعد عمومی قرارداد مندرج در فصل سوم از قسمت دوم قانون
              مدنی و همچنین علم و آگاهی طرفین قرارداد به مفاد مندرج در آن منعقد
              گردید و طرفین قرارداد با امضاء این قرارداد و پیوست ‌ های آن، خود
              را متعهد و ملزم به رعایت و اجرای کلیه مفاد این قرارداد نمودند .
            </Typography>
          </Box>

          <Made title="طرفین قرارداد" number={1}>
            <Typography>
              این قرارداد فی مابین شرکت دانش آوران نور ونداد با شماره ثبت ۷۶۷۵۴
              و شناسه ملی ۱۴۰۰۹۹۹۹۱۸۲ به نمایندگی آقای سید مصطفی میرقاسمی و تلفن
              همراه ۰۹۳۵۴۷۸۸۷۳۵ که از این پس طرف اول نامیده می شود و آقای / خانم
              {insert(user.name)}
              به کد ملی
              {insert(user.information.nationalCode, 'number')}
              فرزند
              {insert(user.information.fatherName)}
              و تلفن همراه
              {insert(user.phoneNumber, 'number')}
              که از این پس طرف دوم نامیده می شود، منعقد می گردد .
            </Typography>
          </Made>
          <Made number={2} title="تعاریف">
            <Typography>
              یوتیوب : منظور وبسایت به آدرس youtube.com یا سایت های وابسته به آن
              است که طرف دوم با انتشار ویدیو در آن وبسایت، کسب درآمد می کند .
            </Typography>
            <Typography>
              کانال یوتیوب: منظور از کانال یوتیوب، حساب کاربری ایجادشده در
              پلتفرم یوتیوب (YouTube) است که به مالک آن امکان بارگذاری، مدیریت و
              انتشار محتوای ویدئویی را می‌دهد و تحت شرایط و قوانین این پلتفرم
              عمل می‌کند. کانال مذکور می‌تواند شامل نام کاربری، لوگو، محتوای
              منتشرشده، و سایر اطلاعات مرتبط با فعالیت‌های مالک آن باشد. منتشرشده، و سایر اطلاعات مرتبط با فعالیت‌های مالک آن باشد.
              <br />
              در این قرارداد، منظور از کانال یوتیوب، کانال یوتیوب طرف دوم به
              آدرس زیر می باشد:
              <br />
              {insert(channelUrl, 'number')}
            </Typography>

          </Made>
        </Page>

        <Page footer={signSection}>
          <Made number={2} title="تعاریف">
            <Typography>
              شرکت‌گوگل:
              منظور از شرکت گوگل، شرکت Google LLC، که به‌عنوان ارائه‌دهنده خدمات
              اینترنتی و فناوری ازجمله موتور جستجو، پلتفرم یوتیوب، سرویس‌های
              تبلیغاتی، و سایر محصولات مرتبط شناخته می‌شود.
            </Typography>

            <Typography>
              حساب کاربری یوبر: حسابی است که توسط کاربر در سامانه یوبر (به آدرس
              اینترنتی panel.youber.app) ایجاد شده و کاربر از طریق آن کانال یا
              کانال‌های خود را به سامانه متصل نموده است.
            </Typography>
            <Typography>
              دسترسی مدیریت کانال یوتیوب: سطح دسترسی‌ای است که صاحب کانال یوتیوب
              برای مدیریت کانال خود به یک شخص یا سامانه ثالث اعطا می‌کند. این
              دسترسی شامل امکان انجام فعالیت‌هایی نظیر مدیریت ویدئوها، تحلیل
              داده‌ها، و مشاهده گزارش‌ها در چارچوب مجوزهای تعریف‌شده توسط یوتیوب
              می‌باشد، بدون انتقال مالکیت کانال.
            </Typography>
            <Typography>
              ادسنس یا گوگل ادسنس : منظور یکی از سرویس های گوگل ( google.com )
              است که به ناشران تبلیغ اجازه می دهد تا تبلیغ های متنی، تصویری /
              بنری، ویدئویی یا هر نوع تبلیغ چند رسانه ای که با محتوا و مخاطبان
              شان ارتباط دارد، را بر روی ویدیوهای خود قرار دهند و از طریق نمایش
              این تبلیغات کسب درآمد کنند .
            </Typography>
            <Typography>
              استرایک یوتیوب: به معنای اخطار، هشدار، یا تحریمی است که پلتفرم
              یوتیوب به دلیل نقض قوانین و مقررات خود، از جمله قوانین مربوط به حق
              مؤلف، محتوای نامناسب، اسپم، یا سایر موارد مشابه، به کانال طرف دوم
              صادر نماید. این امر می‌تواند منجر به محدودیت در آپلود ویدیو، مسدود
              شدن موقت یا دائمی کانال، یا سایر عواقب منفی برای کانال طرف دوم
              گردد.
            </Typography>
            <Typography>
              وضعیت مانتایز (MONETIZED):
              وضعیتی است که در آن کانال یوتیوب تمامی شرایط و الزامات لازم برای کسب درآمد از طریق برنامه مشارکت یوتیوب (YouTube Partner Program - YPP) را برآورده کرده و قابلیت درآمدزایی آن توسط یوتیوب فعال شده است.
            </Typography>
          </Made>
          <Made number={3} title="موضوع قرارداد">
            <Typography>
              موضوع قرارداد عبارت است از دریافت درآمد طرف دوم که از طریق حساب
              ادسنس متصل به کانال یوتیوب متعلق به طرف دوم به حساب طرف اول واریز
              گردیده، و انتقال این درآمد به داخل کشور و واریز سهم طرف دوم به
              حساب معرفی شده توسط وی در این قرارداد .
            </Typography>
          </Made>
          <Made number={4} title="مدت اجرای قرارداد">
            <Typography>
              مدت اجرای این قرارداد از تاریخ انعقاد قرارداد به مدت یک سال می
              باشد . در انتھای مدت اجرای قرارداد، در صورتی که هر یک از طرفین به
              صورت رسمی درخواستی مبنی بر عدم تمدید قرارداد به طرف دیگر قرارداد
              ارائه ننماید، مدت اجرای قرارداد تا یک سال دیگر تمدید خواهد شد.
            </Typography>
          </Made>

        </Page>

        <Page footer={signSection}>
          <Made number={5} title="مبلغ قرارداد">
            <Typography>
              مبلغ موضوع قرارداد بر اساس توافق فی مابین به شرح زیر می باشد :
            </Typography>
            <Typography>
              {insert(user.percent, 'number')}
              درصد از مبلغ درآمد واریزی طبق ماده ۳ قرارداد متعلق به طرف اول
              خواهد بود و الباقی مبلغ درآمد، به حساب طرف دوم واریز خواهد شد .
            </Typography>
            <Typography>
              تبصره ۱: با توجه به اینکه مبلغ پوند به صورت حواله کرد به ایران
              انتقال پیدا می کند، نرخ تبدیل پوند به ریال بر اساس نرخ پوند صرافی
              در روز انتقال محاسبه می گردد .
            </Typography>
            <Typography>
              تبصره ۲: در صورتی که در هر یک از ماه‌های دوره همکاری، مجموع درآمد
              کانال یوتیوب طرف دوم کمتر از شصت (۶۰) پوند باشد، در آن ماه به جای
              اعمال کارمزد درصدی مطابق با ماده ۵، مبلغ ثابت  ۱۰.۲ پوند به
              عنوان کارمزد به طرف اول تعلق خواهد گرفت.
            </Typography>
          </Made>
          <Made number={6} title="نحوه پرداخت">
            <Typography>
              مبلغ محاسبه شده طبق ماده ۵ ، به صورت ماهانه، طی ۲ تا ۱۲ روز پس از
              واریز از طرف شرکت یوتیوب به شماره شبایی که به نام طرف دوم است و توسط وی
              در حساب کاربری یوبر ثبت گردیده است، واریز می گردد
              .
            </Typography>
            <Typography>
              تبصره ۳: مبلغ حد نصاب برای پرداخت به طرف دوم ۳۰ پوند می‌باشد یعنی
              تا زمانی که مبلغ درآمد طرف دوم (پس از کسر کارمزد) نزد طرف اول به
              ۳۰ پوند نرسیده باشد، این مبلغ نزد طرف اول امانت می‌ماند و با اضافه
              شدن درآمد در ماه‌های آتی و رسیدن به حدنصاب ۳۰ پوند به طرف دوم
              پرداخت می‌شود.
            </Typography>
          </Made>

          <Made number={7} title="تعهدات طرف دوم">
            <Typography>
              ۱-۷- هویت و اطلاعات تماس طرف دوم باید دقیقاً مشخص باشد و در صورت
              بروز هرگونه مشکل امکان تماس و پیگیری وجود داشته باشد . در صورت
              تغییر هر کدام از اطلاعات تماس، طرف دوم باید سریعا مورد را به طرف
              اول اطلاع دهد، در غیر اینصورت تمام عواقب و خسارات این موضوع برعهده
              طرف دوم خواهد بود . ضمنا کپی کارت ملی طرف دوم نیز به قرارداد ضمیمه
              می گردد .
            </Typography>
            <Typography>
              ۲-۷- طرف دوم متعهد می‌گردد که کلیه محتوای منتشرشده در کانال یوتیوب به‌طور کامل توسط طرف دوم تولید شده باشد یا وی
              نماینده قانونی تولیدکننده محتوا باشد و مجوزهای لازم برای انتشار و
              استفاده از محتوا را از تولیدکننده اصلی دریافت کرده باشد. در هر
              صورت، طرف دوم مسئولیت کامل صحت، اصالت، و عدم نقض حقوق مالکیت فکری
              مرتبط با محتوای ارائه‌شده را بر عهده خواهد داشت. در صورت بروز
              هرگونه ادعا یا خسارت ناشی از عدم رعایت این تعهد، مسئولیت جبران
              خسارات وارده بر عهده طرف دوم خواهد بود.

            </Typography>
          </Made>
        </Page>
        <Page>
          <Made number={7} title="تعهدات طرف دوم">
            <Typography>
              ۳-۷- طرف دوم تأیید می‌نماید که به طور کامل از قوانین و مقررات حاکم بر تولید محتوا در پلتفرم یوتیوب، به
              ویژه قوانین مربوط به حق مؤلف، محتوای نامناسب، تبلیغات و ...، آگاهی کامل داشته
              فعالیت و انتشار محتوا توسط طرف وی در راستای قوانین یوتیوب و قوانین جمهوری
              اسلامی ایران باشد . در صورت مشاهده هرگونه فعالیت مشکوک و یا انتشار
              محتوایی خلاف این قوانین، طرف اول حق دارد نسبت به قطع همکاری با طرف
              دوم تصمیم گیری و اقدام کند .

            </Typography>
            <Typography sx={{ pb: 0, mb: 0 }}>
              برخی از موارد ممنوع در تولید محتوا که مصداق خاتمه همکاری می باشد
              عبارتند از :
            </Typography>
            <Typography component="div" sx={{ mt: -1 }}>
              <ul>
                <li>تجاوز به حریم خصوصی و قانونی دیگران به روش های مختلف</li>
                <li>
                  ضرر و زیان به دیگران یا دارایی های تحت اختیار شان اعم از
                  مالکیت معنوی و مادی
                </li>
                <li>برهم زدن نظم عمومی جامعه و رعایت نکردن ممیزات اخلاقی</li>
                <li>
                  مصادیق مجرمانه طبق قانون مجازات اسلامی، جرایم رایانه ای، تجارت
                  الکترونیک و سایر قوانین جمهوری اسلامی ایران
                </li>
                <li>شوخی یا توهین به یک نژاد، قمیت، مذهب، ملیت یا جنسیت خاص</li>
                <li>مطالب و ادعای علمی بدون ارائه منابع موثق</li>
                <li>مطالب غیر قابل اثبات و گمراه کننده</li>
                <li>محتواهای ناقض قوانین گوگل</li>
              </ul>
            </Typography>

            <Typography>
              ۴-۷- در صورت دریافت هرگونه استرایک توسط کانال طرف دوم، طرف اول مجاز خواهد بود تا در صورت لزوم، محتوای خطرناک را از کانال حذف نماید و در شرایط ضروری، حساب AdSense متصل به کانال را تغییر دهد. این اقدام ممکن است منجر به عدم واریز درآمد کانال برای حداقل یک ماه یا تا زمانی که کانال دارای استرایک فعال باشد، گردد. طرف دوم حق هیچ‌گونه ادعایی در این خصوص نخواهد داشت.
            </Typography>
            <Typography>
              ۵-۷- طرف دوم حق ندارد در طی مدت این قرارداد، کانال یوتیوبی که متعلق
              به خود بوده و در این قرارداد به عنوان منبع درآمد قید گردیده است را
              حتی به صورت موقت به شخص دیگری واگذار نماید . در غیر اینصورت کلیه
              عواقب و مسئولیت ھا برعھده طرف دوم خواھد بود.
            </Typography>
            <Typography>
              ۶-۷- در طول مدت قرارداد، طرف دوم حق همکاری با سایر سرویس دهنده ها
              جهت نقد کردن درآمد کانال یوتیوب خود را نخواهد داشت .
            </Typography>
            <Typography>
              ۷-۷- طرف دوم (یوتیوبر) متعهد می‌گردد که در طول مدت همکاری با طرف اول، از هرگونه تبلیغ مستقیم یا غیرمستقیم برای شرکت‌های رقیب طرف اول خودداری نماید. تبلیغ مستقیم شامل هرگونه معرفی، ترویج یا ارائه خدمات و محصولات شرکت‌های رقیب به‌صورت آشکار است. تبلیغ غیرمستقیم شامل مواردی نظیر شرکت در رویدادها، برنامه‌ها یا فعالیت‌هایی است که اسپانسر یا حامی مالی آن‌ها شرکت‌های رقیب طرف اول باشند، یا اشارات و فعالیت‌هایی که منجر به تقویت یا ترویج موقعیت شرکت‌های رقیب در بازار شود.
            </Typography>
            <Typography>
              ۷-۸-              طرف دوم موظف است دسترسی مدیریت کانال یوتیوب را به حساب معرفی‌شده توسط طرف اول اعطا
              کند. در صورت حذف این دسترسی و عدم بازگرداندن آن، طرف دوم ملزم به پرداخت جریمه‌ای به مبلغ ۳۰۰۰ دﻻر
              به طرف اول خواهد بود.
            </Typography>
          </Made>
        </Page>

        <Page footer={signSection}>

          <Made number={8} title="تعهدات طرف اول">
            <Typography>
              ۱-۸- طرف اول متعهد می‌شود مبلغ درآمد سهم طرف دوم که در ماده ۵
              تعیین گردیده است، را طی ۲ تا ۱۲ روز پس از واریز از طرف شرکت یوتیوب
              به حساب اعلامی از طرف وی که در ماده ۶ آمده است، واریز نماید.
              در صورت هرگونه تأخیر در پرداخت به دلیل قصور و
              اهمال از طرف اول و به دلایل غیرموجه، به ازای هر روز تأخیر، طرف اول
              مکلف است مبلغی معادل 0.2 درصد از مبلغ کل پرداختی را به عنوان خسارت
              تأخیر به طرف دوم پرداخت نماید.
            </Typography>
            <Typography>
              ۲-۸- طرف اول متعهد می گردد تا از اطلاعات شخصی و حساب کاربری طرف
              دوم بطور کامل صیانت نماید .
            </Typography>
            <Typography>
              ۳-۸- اگر حساب ادسنسی که به کانال یوتیوب متصل است، به هر دلیلی بسته
              یا مسدود شود، طرف اول موظف است تلاش خود را در جهت رفع مسدودی و باز
              شدن حساب انجام داده و طرف دوم را از روند فرآیند رفع مسدودی مطلع
              نماید.
            </Typography>
            
            <Typography>
              ۴-۸- طرف اول متعهد می‌شود به صورت دوره‌ای و هر شش ماه یکبار، در
              صورت درخواست طرف دوم، مشاوره رایگان در زمینه تحلیل و بررسی کانال،
              سئو، تدوین و طراحی و موضوعات تخصصی یوتیوب به طرف دوم ارائه نماید.
              مدت زمان هر جلسه مشاوره حداقل 45 دقیقه خواهد بود.
            </Typography>
            <Typography>
              ۵-۸- طرف اول متعهد می‌شود در حد توان و بضاعت خود، به سوالات و
              مشکلات فنی طرف دوم در رابطه با کانال یوتیوب، در سریع‌ترین زمان
              ممکن پاسخ داده و نسبت به رفع مشکلات اقدام نماید. طرف اول همچنین
              متعهد می‌شود که در صورت بروز هرگونه مشکل برای کانال یوتیوب طرف
              دوم، تمام تلاش خود را برای حل مشکل و رفع مشکل کانال به کار گیرد.
            </Typography>
            <Typography>
              ۶-۸- طرف اول متعهد می‌شود که کلیه اقدامات لازم را برای حفظ امنیت
              کانال یوتیوب طرف دوم، از جمله استفاده از ابزارهای امنیتی مناسب و
              رعایت استانداردهای امنیتی، انجام دهد. در صورت وقوع هرگونه حادثه
              امنیتی که منجر به خسارت به کانال یوتیوب طرف دوم شود، طرف اول متعهد
              است تا تمام تلاش خود را در جهت برطرف شدن حادثه اتفاق افتاده، انجام
              دهد.
            </Typography>
            <Typography>
              ۷-۸- طرف اول متعهد می‌شود که کلیه اطلاعات مالی و شخصی طرف دوم را
              محرمانه تلقی نموده و از آن محافظت نماید. طرف اول تنها در صورتی
              مجاز به افشای این اطلاعات است که قانون صراحتاً وی را به این امر
              ملزم نماید.
            </Typography>
          </Made>

        </Page>
        <Page footer={signSection}>
          <Made number={9} title="حل اختلاف">
            <Typography>
              طرفین کوشش خواهند کرد که کلیه اختلافات ناشی از تفسیر و اجرای این
              قرارداد را به طور دوستانه حل و فصل نمایند . در غیر این صورت نظام
              صنفی رایانه ای خراسان رضوی به عنوان داور توسط دو طرف تعیین خواهد شد و رای
              شورای مزبور به عنوان رای حاکم برای طرفین لازم الاجرا می باشد .
            </Typography>
          </Made>
          <Made number={10} title="قوانین کلی توافقنامه">
            <Typography>
              ۱-۱۰- در تمام طول مدت این قرارداد و یک سال بعد از اتمام مدت زمان
              قرارداد، طرفین اجازه پایین آوردن ارزش تجاری یکدیگر به هر شکل و
              نوعی و یا استخدام کارمندان شرکت مقابل را نداشته و تمامی اقدامات
              غیرقانونی تلقی می شود و شخص متخلف ملزم به جبران خسارت وارده به طرف
              دیگر می باشد
            </Typography>
            <Typography>
              ۲-۱۰- اگر الحاقیه هایی در طول مدت زمان این قرارداد تنظیم شود، باید
              به قرارداد اصلی اضافه گردد و ارزش این الحاقیه از الحاقیه های قبلی
              بالاتر است .
            </Typography>
            <Typography>
              ۳-۱۰- همه اطلاعیه ها و خواسته ها و ارتباطات رسمی طرفین که بر مفاد
              این قرارداد و اجرای پروژه موثر باشد، باید از طریق ایمیل رسمی طرفین
              که در ماده ۱۴ آمده است، ارسال گردد .
            </Typography>

            <Typography>
              ۴-۱۰- در صورتی که حساب گوگل ادسنس به دلیل اعمال مقررات گوگل، تحریم‌های ناعادلانه، قوانین کشور ثالث محل ثبت حساب ادسنس، یا هر دلیل دیگری که خارج از کنترل و اختیارات طرف اول باشد مسدود شود، درآمدهای موجود در آن ضبط یا بلوکه گردد، و یا حساب‌های کاربری متصل به آن حساب ادسنس از قابلیت درآمدزایی محروم شوند، طرف اول هیچ‌گونه مسئولیتی در قبال جبران خسارات نخواهد داشت. با این حال، طرف اول موظف است با ارائه مستندات و مدارک مرتبط به‌صورت شفاف، موضوع را برای طرف دوم توضیح داده و در حد امکان اقدامات لازم جهت برطرف کردن مشکل را انجام دهد.
            </Typography>
            <Typography>
              ۵-۱۰- در صورتی که به دلیل عدم رعایت مقررات که توسط طرف دوم، خسارتی به طرف اول به هر نحوی اعمال گردد،
              طرف دوم موظف است تمام این خسارت ها را جبران نماید .

            </Typography>

            <Typography>
              ۶-۱۰- درآمد کانال فقط در شرایطی که کانال یوتیوب در شرایط درآمدزایی (MONETIZED) باشد واریز میشود.

            </Typography>
            <Typography>
              ۷-۱۰-
              به‌منظور پیشگیری از دریافت استرایک و افزایش امنیت حساب Google AdSense، در صورتی که کانال در بازه زمانی ۹۰ روزه بیش از یک استرایک دریافت نماید، به ازای هر استرایک اضافی، طرف دوم موظف است مبلغ ۱,۰۰۰ دلار آمریکا به‌عنوان جریمه به طرف اول پرداخت کند.
            </Typography>
            <Typography>
              ۸-۱۰-
              در صورت دریافت هرگونه استرایک توسط کانال طرف دوم، طرف اول مجاز خواهد بود تا در صورت لزوم، محتوای خطرناک را از کانال حذف نماید و در شرایط ضروری، حساب AdSense متصل به کانال را تغییر دهد. این اقدام ممکن است منجر به عدم واریز درآمد کانال برای حداقل یک ماه یا تا زمانی که کانال دارای استرایک فعال باشد، گردد. طرف دوم حق هیچ‌گونه ادعایی در این خصوص نخواهد داشت.
            </Typography>
 

          </Made>

        </Page>

        <Page footer={signSection}>
          <Made number={11} title="قوانین و شرایط همکاری در فروش">
            <Typography>
              در صورتی که یوتیوبر دیگری از سمت طرف دوم به طرف اول معرفی شده و
              همکاری قطعی بین طرف اول و یوتیوبر معرفی شده ایجاد شود، طرف اول
              متعهد می گردد که به مدت ۶ ماه، ماهانه ۱.۵ درصد از درآمد ماهانه
              یوتیوبر معرفی شده را به حساب یوتیوبر معرفی کننده و ذکر شده در ماده
              ۱ این قرارداد واریز نماید .
            </Typography>
            <Typography>
              در صورتی که طرف دوم بیش از یک کانال خود را به منظور نقد کردن به
              طرف اول معرفی نماید، در کانال های دوم به بعد به مدت ۶ ماه، ۱.۵ درصد از کارمزد
              طرف اول که در ماده ۵ آورده شده است، کسر خواهد شد .
            </Typography>
          </Made>
          <Made number={12} title="فسخ قرارداد">

            <Typography>
              هر یک از طرفین می‌تواند در هر زمان از مدت قرارداد، در صورت عدم تمایل به ادامه همکاری، قرارداد را فسخ کند؛ به شرطی که حداقل یک ماه قبل از تاریخ مورد نظر برای فسخ، به صورت رسمی به طرف دیگر اطلاع دهد. در صورت فسخ یک‌جانبه و بدون اطلاع‌رسانی یک‌ماه قبلی، متخلف باید خسارت‌های وارد شده به طرف مقابل را به‌طور کامل جبران کند.
            </Typography>
            <Typography>
              تبصره ۴:
              در صورت تخلف طرف دوم از قوانین و مقررات جاری بالاخص قانون
              جرائم رایانه ای و مالکیت فکری و معنوی و عدم تعهد به موارد ماده ۷، طرف اول می تواند با اعلام
              کتبی به طرف دوم، قرارداد حاضر را به صورت یکطرفه فسخ نماید .
              همچنین در صورت تخلف  طرف اول و عمل ننمودن به تعهدات ذکر شده در قرارداد و در صورتی که
              متخلف طرف اول شناسایی گردد، طرف دوم می تواند با اعلام رسمی به طرف اول، قرارداد حاضر را به صورت یکطرفه فسخ نماید .

            </Typography>

          </Made>
          <Made number={13} title="فورس ماژور">
            <Typography>
              در صورت بروز هرگونه اتفاقات غیر منتظره نظیر سیل، زلزله و غیره که
              باعث وقفه در انجام تعهدات گردد، طرف اول موظف به پرداخت هیچگونه
              خسارتی به طرف دوم نخواهد بود . اما پس از پایان شرایط فورس ماژور،
              تعهدات به حالت خود باقی است و هر دو طرف موظف هستند که مسئولیت و
              تعهدات تعریف شده در این قرارداد را به نحو احسن به انجام برساند .
            </Typography>
          </Made>

        </Page>
        <Page footer={signSection}>
          <Made number={14} title="اطلاعات تماس طرفین">
            <Typography>
              طرفین موظف هستند تا ارتباطات رسمی و اطلاعیه ها را از طریق ایمیل
              هایی که در این ماده ذکر گردیده است، ارسال نمایند .
            </Typography>
            <Typography>
              ایمیل رسمی طرف اول :
              {insert('info@youber.app')}
            </Typography>
            <Typography>
              ایمیل رسمی یوتیوبر :
              {insert(user.email)}
            </Typography>
          </Made>
          <Made number={15} title="مواد">
            <Typography>
              این قرارداد با ۱۵ ماده و ۴ تبصره، در ۲ نسخه که هر کدام حکم نسخه
              واحد را دارند، در تاریخ
              {insert(new Date().toLocaleDateString('fa-IR'))}
              تنظیم گردیده است و از زمان امضای قرارداد، تمام موارد لازم الاجرا
              خواهد بود .
            </Typography>
          </Made>
        </Page>
      </div>
    </ThemeProvider>
  );
}

function Page({ children, footer }) {
  return (
    <div
      style={{
        pageBreakAfter: 'always',
        pageBreakInside: 'avoid',
        position: 'relative',
        height: '95vh',
        // background: 'rgba(0,0,0,0.1)',
      }}
    >
      {children}
      <div style={{ position: 'absolute', bottom: 0 }}>{footer}</div>
    </div>
  );
}

function Made({ number, title, children }) {
  return (
    <Box
      sx={{
        py: 3,
        px: 2,
        backgroundColor: '#58585812',
        my: 2,
        borderRadius: 4,
      }}
    >
      <Typography
        variant="h3"
        gutterBottom
        sx={{
          borderImage:
            'linear-gradient(183deg, rgba(253,29,29,0.528470763305322) 26%, rgba(252,176,69,0.6349133403361344) 100%) 1',
          borderWidth: 4,
          borderStyle: 'solid',
          borderBottom: 'none',
          borderLeft: 'none',
          borderTop: 'none',
          px: 2,
        }}
      >
        ماده
        {' '}
        {number?.toLocaleString('fa-IR')}
        {' | '}
        {title}
      </Typography>
      {children}
    </Box>
  );
}
