export var ChannelState;
(function (ChannelState) {
  ChannelState.UNKNOWN = 'UNKNOWN';
  ChannelState.REJECTED = 'REJECTED';
  ChannelState.UNDER_REVIEW = 'UNDER_REVIEW';
  ChannelState.DEMONETIZED = 'DEMONETIZED';
  ChannelState.ACCEPTED = 'ACCEPTED';
  ChannelState.MONETIZED = 'MONETIZED';
  ChannelState.FOR_FUTURE = 'FOR_FUTURE';
  ChannelState.ICE_BOX = 'ICE_BOX';
}(ChannelState || (ChannelState = {})));
