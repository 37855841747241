import { userUpdateHandler } from 'data-provider/user-handlers';
import baseDataProvider from './rest-client';

const decorators = [
  {
    action: 'update',
    resource: 'user',
    handler: userUpdateHandler,
  },

  extendFiltersDecorator({
    action: 'getMany',
    resource: 'user',
    filters: { _attributes: 'id,name,email,channels' },
  }),
  extendFiltersDecorator({
    action: 'getList',
    resource: 'user',
    filters: { _attributes: 'id,name,email,channels,gbpCredit,information,bankAccount,phoneNumber,preferredPayment' },
  }),

];

function extendFiltersDecorator({ action, resource, filters }) {
  return {
    action,
    resource,
    handler(resource, params) {
      return baseDataProvider[action](resource, {
        ...params,
        filter: {
          ...params.filter,
          ...filters,
        },
      });
    },
  };
}

export default Object.keys(baseDataProvider).reduce(
  (all, action) => ({
    ...all,
    [action](resource, params) {
      const decorator = decorators.find(
        (d) => d.action === action && d.resource === resource,
      );
      if (decorator) {
        return decorator.handler(resource, params);
      }
      return baseDataProvider[action](resource, params);
    },
  }),
  {},
);
